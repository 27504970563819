.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.wrapper {
  width: max-content;
  height: 30px;
  padding-left: 13px;
  padding-right: 13px;
  position: relative;
  border-radius: 24px;
  background-color: var(--psp-pink);
  /* transition: 300ms; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input[disabled] + .wrapper {
  background-color: var(--red-hue01);
}

.wrapper .slider {
  height: 24px;
  min-width: 24px;
  padding: 2px 10px;
  position: absolute;
  left: 3px;
  bottom: 3px;
  border-radius: 12px;
  background-color: var(--psp-white);
  /* transition: 300ms; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.input:checked + .wrapper .slider {
  /* transform: translateX(20px); */
  left: unset;
  right: 3px;
}

.divider {
  width: 18px;
}

.label {
  font-weight: 500;
  font-size: 12px;
  color: var(--psp-black);
  letter-spacing: 0.05rem;
  text-align: center;
}

.icon {
  margin-left: 2px;
  margin-right: 2px;
}
