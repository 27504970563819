.recordItem_iconCard {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  color: #000000;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.recordItem_iconCard .month-start {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;

  color: #334250;

  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.recordItem_iconCard .score-end {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: right;
  letter-spacing: 0.05em;

  color: #334250;

  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.recordItem_iconCard .record_item_chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recordItem_iconCard .record_item_chart-parent {
  position: relative;
  width: 100%;
}

.recordItem_iconCard .score_rank_column {
  display: flex;
  height: 100%;
  align-items: center;
}

.recordItem_iconCard .score_rank_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  --ion-grid-column-padding: 0;
  text-align: center;
  letter-spacing: 0.1em;

  color: #c4c4c4;
}

.recordItem_iconCard .score_rank {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #334250;
}

.recordItem_iconCard .input_link {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  text-align: center;
  letter-spacing: 0.1em;
  color: #677380;

  border-bottom: 1px solid #677380;
}

.recordItem_iconCard .score_text {
  width: 45px;
  text-align: center;
}
