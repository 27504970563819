.ConnectButton {
  position: relative;
  overflow: hidden;

  height: 44px;
  border-radius: 22px;
  width: 90px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  --background: var(--psp-gray04);
  --color: var(--psp-black);
}

.ConnectButton div {
  position: relative;
}

.ConnectButtonDisabled {
  opacity: 1 !important;
  --background: var(--psp-blue-hue01) !important;
}

.ConnectButtonFailed {
  opacity: 1 !important;
  color: var(--psp-red) !important;
  --background: var(--psp-white) !important;
  border: var(--border-red);
}
