/* 薬リストのスタイル */

.prescription_item_iconCard {
  height: 110px;
  width: 100%;
  background-color: var(--psp-white);
  border-radius: 15px;
  --ion-card-color: (--text-color) !important;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.delete_item_div {
  height: 110px;
  width: calc(100% - var(--psp-all-margin));
  background-color: var(--psp-pink);
  border-radius: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  position: absolute;
}

.prescription_item_ionCardContent {
  margin: 0;
  padding: 0;
}

.medicineList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.medicineDiv {
  width: 90px;
  height: 90px;
  margin: 10px;
}

.medicineImg {
  background: var(--psp-gray05) !important;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}

.medicineInfo {
  height: 110px;
  width: 215px;
}

.medicineTitle {
  margin-top: 15px;
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.medicineFrequency {
  margin-top: 10px;

  display: flex;
  align-items: center;
}

.medicineNum {
  margin-top: 5px;

  display: flex;
  align-items: center;
}

/* ページのスタイル */

.content {
  margin: 0 25px;
}

.pageTitle {
  height: 34px;

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  letter-spacing: 0.1em;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pageEmptyTitle {
  color: var(--psp-gray03) !important;
}

.displayWrap {
  overflow-wrap: break-word;
  word-break: break-word;
}

.imgAdd {
  width: 100%;
  height: calc((100vw - var(--psp-page-left-right-space) - var(--psp-page-left-right-space) - 10px ) / 3);
  background: var(--psp-white);
  border-radius: 15px;
}

.buttonDiv {
  position: sticky;
  bottom: 30px;
  z-index: 2;
}

.imgWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.photo {
  width: 100%;
  height: calc((100vw - var(--psp-page-left-right-space) - var(--psp-page-left-right-space) - 10px ) / 3);
  object-fit: cover;
  border-radius: 15px;
}
