.root {
  margin-left: 20px;
  margin-right: 20px;
}

.dot {
  width: 14px;
  height: 14px;
  position: relative;
  border: 1px solid var(--psp-gray03);
  border-radius: 50%;
}

.dot:not(:first-child) {
  margin-left: 20px;
}

.dotChecked {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background-color: var(--psp-pink);
  border-radius: 50%;
}
