.divContainer {
  width: 282px;
  height: 170px;
  border-radius: 15px;
  position: relative;
}

.img {
  width: 282px;
  height: 170px;
  border-radius: 15px;
}

.divContainerTool {
  margin-top: -170px;
  width: 282px;
  height: 170px;
  border-radius: 15px;
  position: absolute;
}

.divTag {
  margin-top: 15px;
  margin-left: 15px;
  position: absolute;
}

.divTitle {
  margin-top: 120px;
  margin-left: 15px;
  position: absolute;
}

.title{
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;
  color: var(--text-color);

  height: 20px;
}

.subTitle{
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;
  color: var(--text-sub-title-color);

  height: 15px;
}

.divFavorite {
  margin-top: 10px;
  margin-left: 232px;
  width: 40px;
  position: absolute;
}

.divPlay {
  margin-top: 120px;
  margin-left: 232px;
  width: 40px;
  position: absolute;
}