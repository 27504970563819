.divContainer {
  width: var(--psp-small-title-width);
  height: 113px;
  background: var(--psp-white);
  border-radius: 15px;
}

.divContainerTwoLine {
  width: var(--psp-small-title-width);
  height: var(--psp-small-title-height-lite);
  background: var(--psp-white);
  border-radius: 15px;
}

.divContainerTag {
  width: var(--psp-small-title-width);
  height: var(--psp-small-title-height);
  background: var(--psp-white);
  border-radius: 15px;
}

.divContainerTagNoTitle {
  width: var(--psp-small-title-width);
  height: var(--psp-small-title-height-lite);
  background: var(--psp-white);
  border-radius: 15px;
}

.tagDiv {
  display: flex;
  justify-content: center;
  margin: 15px auto 0px auto;
}

.image {
  width: 83px;
  height: 55px;
}

.image_margin_one {
  margin: 15px 40px;
}

.image_margin_two {
  margin: 10px 40px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;
  color: var(--text-color);

  height: 20px;
}
