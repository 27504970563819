.pageTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.displayTitle {
  height: 22px;

  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.displayDiv {
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.displayWrap {
  min-height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.imgAdd {
  width: 110px;
  height: 110px;
  background: var(--psp-gray05);
  border-radius: 15px;
}
