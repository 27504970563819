.divContainer {
  width: 282px;
  height: 170px;
  background: var(--psp-white);
  border-radius: 15px;
}

.image {
  width: 150px;
  height: 85px;
}

.imageDiv {
  margin: 0 66px;
}

.textDive {
  margin: 0 0 0 15px;
}

.title {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;
  color: var(--text-color);

  height: 20px;
}

.subTitle {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;
  color: var(--text-sub-title-color);

  height: 15px;
}

.divPlay {
  position: absolute;
  margin-top: -34px;
  margin-left: 243px;
}
