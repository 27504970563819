.root::part(content) {
  background-color: var(--psp-white);
  border-radius: 0;
}

.contentWrapper::part(background) {
  background-color: var(--psp-white);
}

.header {
  padding: 13px 9px;
  background-color: var(--psp-system-gray);
}

.content {
  margin-left: var(--psp-page-left-right-space);
  margin-right: var(--psp-page-left-right-space);
}

.label {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: var(--psp-black);
}
