.root::part(background) {
  /* background-color: white; */
}

.title {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
}

.col {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name {
  margin-top: 5px;
  /* background-color: #E2E2E2; */
  font-size: 12px;
  text-align: center;
  text-transform: lowercase;
}
