.divBox {
  display: flex;
}

.new {
  background: var(--psp-red);
  width: 7px;
  height: 7px;

  border-radius: 50%;
}

.connected {
  background: var(--psp-blue);
  width: 5px;
  height: 5px;

  border-radius: 50%;
}

.not_connected {
  background: var(--psp-gray02);
  width: 5px;
  height: 5px;

  border-radius: 50%;
}
