.img {
  width: 150px;
  height: 150px;
}

.title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.05rem;
}

.desc {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1rem;
}
