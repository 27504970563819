.inputLabel {
  padding: 10px;
  font-family: "Noto Sans JP";
  font-weight: 700;
  font-size: 20px;
}

.timeInputLabel {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1em;
}
