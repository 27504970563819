.tab_bar {
  margin: 15px var(--psp-page-left-right-space);
  border-top: none;
}

.tab_button_text {
  min-width: max-content;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
}

.tab {
  color: var(--psp-black);
}
