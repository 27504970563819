.root {
  width: 390px;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  background-color: var(--psp-gray04);
}

.divider {
  width: 1px;
  height: calc(100% - 22px);
  margin-top: 11px;
  background-color: var(--psp-gray01);
}

.preBtnAnimation > .divider,
.nextBtnAnimation > .divider {
  background-color: transparent;
}

.leftBtn,
.rightBtn {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
}

.leftBtn {
  justify-content: flex-start;
}

.rightBtn {
  justify-content: flex-end;
}

.leftBtn::before,
.rightBtn::before {
  width: 0;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  background-color: var(--psp-black);
  z-index: -1;
}

.preBtnAnimation > .leftBtn::before {
  right: 0;
  animation: spread-width 500ms cubic-bezier(0.014, 0.421, 0.347, 1);
}

.nextBtnAnimation > .rightBtn::before {
  left: 0;
  animation: spread-width 500ms cubic-bezier(0.014, 0.421, 0.347, 1);
}

@keyframes spread-width {
  100% {
    width: 100%;
  }
}

.leftBtn .icon {
  margin-left: 17px;
  margin-right: 7px;
}

.rightBtn .icon {
  margin-left: 7px;
  margin-right: 17px;
}

.label {
  color: var(--psp-black);
}

.preBtnAnimation > .leftBtn .label,
.nextBtnAnimation > .rightBtn .label {
  animation: text-color-change 300ms forwards;
}

@keyframes text-color-change {
  100% {
    color: var(--psp-white);
  }
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.05em;
}

.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.1em;
}

.rightBtn .title,
.rightBtn .content {
  text-align: right;
}

.preBtnAnimation > .leftBtn .icon,
.nextBtnAnimation > .rightBtn .icon {
  /* animation: hide_icon 300ms forwards; */
  animation: contrast_icon 300ms forwards;
}

@keyframes contrast_icon {
  100% {
    stroke: var(--psp-white);
  }
}
