.iosheader {
  padding-top: var(--psp-safe-area-top);
}

.logo {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: var(--psp-page-left-right-space);
  height: 44px;
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  letter-spacing: 0.05em;

  color: var(--psp-black);
}

.topBottomSize {
  margin-bottom: 14px !important;
}

.topBottomLine {
  border-bottom: 1px solid var(--psp-gray04);
}
