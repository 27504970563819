.root::part(content) {
  background-color: var(--psp-white);
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.contentWrapper::part(background) {
  background-color: var(--psp-white);
}

.contentWrapper::part(scroll) {
  overflow: hidden;
}

.content {
  margin-top: 79px;
  margin-left: var(--psp-page-left-right-space);
  margin-right: var(--psp-page-left-right-space);
}

.fixedContent {
  margin-top: 45px;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.75fr 1fr;
  background-color: var(--psp-white);
  z-index: 9;
}

.title {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--psp-black);
}

.back {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.1em;
}

.backBtn {
  transform: translateY(-10px);
}

.cancel {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  letter-spacing: 0.1em;
}

.labelDisabled {
  color: var(--psp-gray03);
}
