.content {
  --background: transparent !important;
  background: transparent !important;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  background: transparent !important;
}

.relative {
  position: relative;
  z-index: 1;
}
.square {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}
.square:after {
  content: "";
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.square > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.surround_cover {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}
.barcode_scanner__area__container {
  width: 80%;
  max-width: min(500px, 80vh);
  margin: auto;
}
.barcode_scanner__area__outer {
  display: flex;
  border-radius: 1em;
}
.barcode_scanner__area__inner {
  width: 100%;
  margin: 1rem;
  border: 2px solid #fff;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.5), inset 0px 0px 2px 1px rgb(0 0 0 / 0.5);
  border-radius: 1rem;
}

.sample-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(45deg, #673ab7, transparent);
  background-position: 45% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  animation: shake 5s infinite;
}
@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(5px, 5px) rotate(-1deg) scale(1.05);
  }
  40% {
    transform: translate(5px, 5px) rotate(-2deg) scale(1.07);
  }
  60% {
    transform: translate(2px, 2px) rotate(0deg) scale(1.04);
  }
  80% {
    transform: translate(-1px, -1px) rotate(-2deg) scale(1.05);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}

.footMessageDiv {
  width: 100%;
  margin: auto;
  position: absolute;
  background: transparent !important;
  left: 0px;
  bottom: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* text-align: center; */
  letter-spacing: 0.1em;

  /* Primary/PSP White */

  color: var(--psp-white);

  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
