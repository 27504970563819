.root {
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.root::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.scrollableTabLineContainer {
  position: absolute;
  left: 0;
  top: 0;
}

.tabItem {
  width: 50%;
  /* min-width: max-content; */
  min-width: 60px;
  padding-bottom: 5px;
  border-bottom: 3px solid var(--psp-gray03);
  border-radius: 1px;

  position: relative;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05rem;
}

.active::after {
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  content: "";
  background: var(--psp-pink);
}

.tabSpace {
  min-width: 10px;
  border-bottom: 3px solid var(--psp-gray03);
}
