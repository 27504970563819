.root {
  --width: calc(100% - 50px);
  --height: max-content;
}

.root::part(content) {
  background-color: transparent;
}

.contentUpAnimation {
  margin-top: 30px;
  animation: content-moving-up 600ms cubic-bezier(0.01, 0.42, 0.35, 1) 300ms forwards;
}

.close {
  margin: 0 auto;
}

@keyframes content-moving-up {
  0% {
    margin-top: 30px;
    margin-bottom: 0;
  }
  100% {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
