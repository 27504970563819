.title {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.1em !important;
  color: var(--psp-black) !important;
}

.hr {
  flex-grow: 1;
  margin-left: 15px;
  background-color: var(--psp-gray03);
}

.subTitleWrapper {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.subTitle {
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--psp-black);
}
