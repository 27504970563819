.input {
  opacity: 0;
  width: 0;
  height: 0;
  /* position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); */
}

.root {
  height: var(--appcheckbox-normal-height);
  display: flex;
  align-items: center;
}

.root.small {
  height: var(--appcheckbox-small-height);
}

.init {
  width: var(--appcheckbox-normal-width);
  height: var(--appcheckbox-normal-height);
  margin-right: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.init.noLabel {
  margin-right: 0;
}

.cover {
  width: var(--appcheckbox-normal-width);
  height: var(--appcheckbox-normal-height);
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--psp-pink);
  border-radius: 50%;
  transform: scale(0);
}

.root.small {
  height: var(--appcheckbox-small-height);
}

.init.small,
.cover.small {
  width: var(--appcheckbox-small-width);
  height: var(--appcheckbox-small-height);
}

.checked {
  border: unset;
}

.checked .cover {
  transform: scale(1);
}

.checkedImg {
  height: var(--appcheckbox-checkmark-normal-height);
  width: 0;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  object-fit: cover;
  object-position: left;
  transition: width 130ms cubic-bezier(0.49, 0, 0.07, 1);
}

.checkedImg.small {
  height: var(--appcheckbox-checkmark-small-height);
  left: 3px;
}

.checked .checkedImg {
  width: var(--appcheckbox-checkmark-normal-width);
}

.checked .checkedImg.small {
  width: var(--appcheckbox-checkmark-small-width);
}

.checking .cover {
  animation: scale-up-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    checking-opacity 130ms forwards;
}

.unchecking .cover {
  animation: scale-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    unchecking-opacity 200ms forwards;
}

.icon {
  width: var(--appcheckbox-normal-width);
  height: var(--appcheckbox-normal-height);
}

.icon.small {
  width: var(--appcheckbox-small-width);
  height: var(--appcheckbox-small-height);
}

.disabled.init {
  border-color: var(--psp-gray03);
}

.disabled.checked.init .cover {
  /* #f2e3e4 is not a common style color defined in stylesheet on figma, and it is only used in this case to display the disabled background color for checkbox */
  background-color: #f2e3e4;
}

.disabled .checkedImg,
.disabled .icon {
  opacity: 0.15;
}

.label {
  font-family: var(--ion-font-family);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.05rem;
  color: var(--psp-black);
}

.small .label {
  margin-left: 10px;
}

@keyframes scale-up-down {
  0% {
    transform: scale(0);
  }
  66% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checking-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes unchecking-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
