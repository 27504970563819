.root-content {
  margin-left: var(--psp-page-left-right-space);
  margin-right: var(--psp-page-left-right-space);
}

.fab-button {
  width: 56px;
  height: 56px;
  --box-shadow: none;
}

.flex-row-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-around {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-row-start {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.div_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-padding {
  padding-left: var(--psp-page-left-right-space);
  padding-right: var(--psp-page-left-right-space);
}

/* app picker style overwrite only */
.app-picker .swiper-slide-active {
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #424242 !important;
}

.white-card {
  padding: 15px;
  background-color: var(--psp-white);
  border-radius: 15px;
}

/* medicine slider */
.medicine-img-sliders .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px !important;
  background-color: var(--psp-gray03);
  opacity: 1;
}

.medicine-img-sliders .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--psp-red);
}

hr {
  margin: 0;
  background-color: var(--psp-gray03);
}

.title-hr {
  background-color: var(--psp-fab-pink);
}

.recharts-tooltip-wrapper {
  outline: none;
}

.home-indicator-bar {
  height: calc(var(--psp-safe-area-bottom) + var(--ion-safe-area-bottom, 0));
}

.connection-wrapper {
  padding: 7px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 18px;
  border: 1px solid var(--psp-black);
}

.connection-dot {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  border-radius: 4px;
  background-color: var(--psp-red);
}

.connection-dot-active {
  background-color: var(--ion-color-green);
}

.modal-content {
  padding: 45px 25px 0;
  background-color: var(--psp-white);
  border-radius: 20px !important;
}
