.divXLarge {
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  background: var(--psp-white);
}

.divLarge {
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  background: var(--psp-white);
}

.divMediumBigBig {
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background: var(--psp-white);
}

.divMediumBig {
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  background: var(--psp-white);
}

.divMedium {
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background: var(--psp-white);
}

.divSmall {
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  background: var(--psp-white);
}

.grayBg {
  background: var(--psp-base-gray);
}

.divCrop {
  position: relative;
  overflow: hidden;
}

.imgXLarge {
  height: 200px !important;
  width: 200px !important;
}

.imgLarge {
  height: 70px;
  width: 70px;
}

.imgMediumBig {
  height: 45px;
  width: 45px;
}

.imgMedium {
  height: 30px;
  width: 30px;
}

.imgSmall {
  height: 20px;
  width: 20px;
}

.noFrame .imgXLarge {
  border-radius: 50%;
  width: 200px !important;
  height: 200px !important;
}

.noFrame .imgLarge {
  border-radius: 50%;
  height: 150px !important;
  width: 150px !important;
}

.noFrame .imgMediumBigBig {
  border-radius: 50%;
  height: 100px !important;
  width: 100px !important;
}

.noFrame .imgMediumBig {
  border-radius: 50%;
  height: 90px !important;
  width: 90px !important;
}

.noFrame .imgMedium {
  border-radius: 50%;
  height: 60px !important;
  width: 60px !important;
}

.noFrame .imgSmall {
  border-radius: 50%;
  height: 44px !important;
  width: 44px !important;
}

.imgDisplay {
  position: absolute;
  top: -9999px;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  margin: auto;
  object-fit: cover;
}
