.iconCard-unsubmit {
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 15px;
  color: #000000;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.iconCardContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.iconCardImg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 4rem;
}

.iconCardLabel {
  height: 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15em;
  color: #334250;
}

.month-start-unsubmit {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;

  color: #334250;

  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.score-end-unsubmit {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: right;
  letter-spacing: 0.05em;

  color: #334250;

  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.unsubmit_msg_div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.unsubmit_msg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  letter-spacing: 0.05em;

  /* 横展開色を修正します。 */
  color: var(--psp-red) !important;
}

.button_column {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.button_style {
  --color: #334250;
  --border-color: #334250;
  --border-style: solid;
  --border-width: 1px;
  margin: 0px;
  height: 36px !important;
}

.button_text {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.1em !important;

  padding: 8px;
}

.score_rank_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  --ion-grid-column-padding: 0;
  text-align: center;
  letter-spacing: 0.1em;

  color: #c4c4c4;
}

.score_rank {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #334250;
}
