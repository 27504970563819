.root {
  position: relative;
}

.sliderWrapper {
  width: 100%;
  position: absolute;
  top: 0;
  background-color: var(--psp-white);
  transition: left 300ms;
}

.slider1 {
  left: 0;
}

.slider1.moveLeft {
  left: calc(-100% + var(--psp-page-left-right-space-negative));
}

.slider2 {
  width: 0;
  left: calc(100% + var(--psp-page-left-right-space));
}

.slider2.moveLeft {
  width: 100%;
  left: 0;
}

.scrollable {
  height: calc(100vh - var(--ion-safe-area-top) - 79px - 36px - 45px) !important;
  /* align-items: flex-start; */
  overflow-y: auto;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.slider2 .header {
  display: none;
}

.slider2.moveLeft .header {
  display: grid;
}

.cancel,
.confrim {
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--psp-black);
}

.confrim {
  text-align: right;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--psp-black);
}

.optionLine {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}

.item,
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--psp-black);
}

.inputWithUnit {
  margin-right: 15px;
}
