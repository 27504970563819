.collapseHeader {
  position: relative;
}

.collapse {
  flex-grow: 1;
}

.icon {
  width: fit-content;
  /* transition: transform 300ms; */
  transform: rotate(0);
}

.collapseExpended {
  transform: rotate(180deg);
}
