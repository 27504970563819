.alertWrapper {
  --width: calc(100% - 50px);
  --height: max-content;
  --border-radius: 15px !important;
}

.alertWrapper::part(content) {
  background-color: transparent;
}

.body {
  padding: 45px 25px;
  background-color: #fff;
  border-radius: 15px !important;
}

.alertImgWrapper {
  flex-direction: column;
}

.alertErrorImg {
  height: 110px;
}

.alertFinishImg {
  width: 105px;
}

.alertCloseBtn {
  height: 56px;
  width: 100%;
  max-width: var(--appbutton-normal-width);
  margin: 0 15px;
}

.alertTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.alertDesc {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
}

.navDetailBtn {
  display: block;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.1em;
}

.rightArrow {
  margin-left: 10px;
  height: 8px;
}

.close {
  width: 44px;
  height: 44px;
  margin: 0 auto;
  display: block;
}
