.input {
  opacity: 0;
  width: 0;
  height: 0;
  /* position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); */
}

.init {
  width: 100px;
  height: 100px;
  position: relative;
  background-color: var(--psp-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.init::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--psp-pink);
  border-radius: 50%;
  transform: scale(0);
  z-index: -1;
}

.checked.init::before {
  transform: scale(1);
}

.checking::before {
  animation: scale-up-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    checking-opacity 130ms forwards;
}

.unchecking::before {
  animation: scale-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    unchecking-opacity 200ms forwards;
}

.label {
  color: var(--psp-black);
  white-space: normal;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.05rem;
}

.disabled.init {
  background-color: var(--psp-gray04);
}

.disabled.checked.init::before,
.disabled.checking.init::before {
  background-color: var(--red-hue01);
}

.disabled .label {
  color: var(--psp-gray02);
}

.multiBtn {
  border-radius: 25px;
}

.multiBtn::before {
  border-radius: 25px;
}

@keyframes scale-up-down {
  0% {
    transform: scale(0);
  }
  66% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checking-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes unchecking-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
