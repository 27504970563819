.selectedDateLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: var(--psp-gray01);
}

.deadlineLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--psp-black);
}

.statusName {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: var(--psp-gray01);
}

.statusText {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--psp-black);
}

.statusTime {
  padding-left: 15px;
}

.statusIcon {
  padding-left: 8px;
  display: inline-block;
}

.noData {
  color: var(--psp-gray01) !important;
}
