.root {
  /* text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; */
  width: fit-content;
}

.xl {
  width: 56px;
  height: 56px;
}

.lg {
  width: 48px;
  height: 48px;
}

.md {
  width: 36px;
  height: 36px;
}

.sm {
  width: 24px;
  height: 24px;
}

.xs {
  width: 16px;
  height: 16px;
}

.xxs {
  width: 12px;
  height: 12px;
}

.illustration {
  width: 54px;
  height: 54px;
}

.special {
  width: 150px;
  height: 150px;
}

.disabledIcon {
  opacity: 0.3;
}

.black {
  stroke: var(--psp-black);
  fill: var(--psp-black);
}

.white {
  stroke: var(--psp-white);
  fill: var(--psp-white);
}
