.divTagOneContainer {
  width: auto;
  height: 22px;
  border-radius: 5px;
  background: var(--tag-background);

  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  /* Primary / PSP White */

  color: var(--tag-color);

  padding: 0 10px;
}

.divTagTwoContainer {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: var(--tag-background);

  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  /* Primary / PSP White */

  color: var(--tag-color);

  padding: 0 8px;
}


.divTagTimeContainer {
  width: auto;
  height: 22px;
  border-radius: 5px;
  background: var(--tag-background-light);

  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  /* Primary / PSP White */

  color: var(--tag-color-light);

  padding: 0 10px;
}

.divTagColorContainer {
  width: auto;
  height: 22px;
  border-radius: 5px;
  background: var(--ion-color-base);
  
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  color: var(--ion-color-contrast);

  /* Primary / PSP White */
  padding: 0 10px;
}

