.content {
    position: relative;
}

.dot {
    width: 24px;
    height: 24px;
    margin-right: 30px;
    border-radius: 12px;
    background-color: var(--psp-gray02);
}

.dot:last-child {
    margin-right: 0;
}

.pink {
    background-color: var(--psp-pink);
}

.keyboardWrapper {
    padding: 6px 6px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 46px 46px 46px 46px;
    gap: 7px;
    background-color: #D1D3D9;
}

.keyboardButton {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4.6px;
}


.keyboardButton:active,
.deleteBtn:active {
    background: #cccccc;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4.6px;
}
