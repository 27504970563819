.labelWithRightIcon {
  margin-right: 5px;
}

.contentLeft {
  margin-left: var(--psp-page-left-right-space) !important;
}

.slidesBarShort {
  height: 122px;
}

.slidesBarShort.withDesc {
  height: 160px;
}

.slideLeft {
  width: 160px !important;
  justify-content: start !important;
}

.slidesWide {
  height: 172px;
}

.slideWide {
  width: 282px;
  justify-content: start !important;
}
