.displayTitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.displayTitle {
  color: var(--text-color);

  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
}

.rankDiv {
  height: 100%;
  display: flex;
  align-items: center;
}
