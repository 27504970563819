.weekDetailDiv {
  background: var(--psp-white);
  border-radius: 15px;
  padding: 0 25px;
  color: var(--text-color);
}

.weekTitleDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.weekTitleScoreDiv {
  display: flex;
  align-items: center;
  line-height: 28px;
  height: 100%;
}

.displayTitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.displayTitle {
  color: var(--text-color);

  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
}

.rankDiv {
  height: 100%;
  display: flex;
  align-items: center;
}

.medicineTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  /* Primary/PSP gray02 */
  color: var(--psp-gray02);
}

.medicineDetail {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* Primary/PSP Black */
  color: var(--text-color);
}

.avgRoot {
  margin-left: 67px;
}

.avgLineWrapper {
  display: flex;
  justify-content: flex-start;
}

.avgIcon {
  margin-right: 22px;
}

.avgTitle {
  padding-top: 5px;
}

.avgHalfYearScore {
  margin-left: 58px;
}
