.chart {
  color: var(--text-color);
}

div.chart canvas {
  z-index: -1;
}

.chart_parent {
  position: relative;
  width: 100%;
  height: 260px;
}

.no_record_warn {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */
  text-align: center;
  letter-spacing: 0.1em;

  color: #334250;
  margin-top: -260px !important;
  height: 100%;
}

.buttonBorderColor {
  --border-color: transparent !important;
}

.leftScoreDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftButton {
  width: 57px;
  height: 57px;

  --border-color: #334250;
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 50%;
  --background: transparent !important;

  --color: #334250;

  --padding-start: 5px !important;
  --padding-end: 5px !important;
  --padding-top: 5px !important;
  --padding-bottom: 5px !important;
}

.leftScore {
  width: 100%;
  height: 100%;

  background: var(--graph-avg-btn-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightScoreDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightButton {
  width: 57px;
  height: 57px;

  --border-color: #334250;
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 50%;
  --background: transparent !important;

  --color: #334250;

  --padding-start: 5px !important;
  --padding-end: 5px !important;
  --padding-top: 5px !important;
  --padding-bottom: 5px !important;
}

.rightScore {
  width: 100%;
  height: 100%;
  background: var(--graph-current-btn-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font {
  text-align: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
}

.gridPosition {
  margin-top: -30px;
}
