.divContainer {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}