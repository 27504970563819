.alertWrapper {
  --width: calc(100% - 50px);
  --height: calc(100vh - 80px) !important;
  --border-radius: 15px !important;
  --appbutton-normal-width: 290px;
}

.alertWrapper::part(content) {
  background-color: transparent;
}

.body {
  padding: 45px var(--psp-page-left-right-space) 45px var(--psp-page-left-right-space);
  background-color: var(--psp-white);
  border-radius: 15px !important;
  color: var(--text-color);
}

.title {
  width: 100%;
  text-align: center;
}

.subTitle {
  width: 100%;
  /* or 167% */
  text-align: center;
}

.navDetailBtn {
  width: 100%;
  text-align: center;
}

.medicineDetailInfo {
  height: calc(100vh - 575px) !important;
  width: 100%;
  background-color: var(--psp-base-gray);
  border-radius: 15px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}