.centerText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextLink {
  height: 28px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  text-align: center;
  letter-spacing: 0.1em;

  color: var( --text-link-color);
}

.TextLinkUnderLine {
  height: 28px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  text-align: center;
  letter-spacing: 0.1em;

  color: var(--text-link-underline-color);

  border-bottom: var(--border);
}

.Small {
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14px !important;

  height: 15px !important;
}
