.textWrapper {
  min-height: 90px;
  margin: 0 15px;
  flex-grow: 1;
  width: 0;
}

.textWrapper > ion-label {
  display: block;
}

.headerLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: var(--psp-black);

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--psp-black);
}

.delete_item_div {
  height: 120px;
  width: calc(100% - var(--psp-all-margin));
  background-color: var(--psp-pink);
  border-radius: 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 15px;
  position: absolute;
  z-index: 0;
}

.medicationStatusCard_ionCardContent {
  margin: 0;
}

.medicationStatusCard_iconCard {
  height: 120px;
  width: 100%;
  border-radius: 15px;
  --ion-card-color: (--text-color) !important;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.medicationStatusCard_iconCard_white {
  background-color: var(--psp-white) !important;
}

.medicationStatusCard_iconCard_gray {
  background-color: var(--psp-base-gray) !important;
}
