.pickerRoot {
  position: relative;
}

.pickerRoot::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 81px;
  bottom: 81px;
  background-color: #eeeeee;
  border-radius: 8px;
}

.pickerWrapper {
  height: 196px;
}

.pickerItem {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #bdbdbd;
}

.left {
  width: calc(50vw - 25px);
  text-align: right;
}

.right {
  width: calc(50vw - 25px);
  text-align: left;
}

.triLeft {
  width: calc(33vw - 25px);
  text-align: right;
}

.triMiddle {
  width: calc(34vw - 25px);
  text-align: middle;
}

.triRight {
  width: calc(33vw - 25px);
  text-align: left;
}

.divider {
  width: 50px;
}
