.medicine_item_iconCard {
  height: 110px;
  width: 100%;
  background-color: var(--psp-base-gray);
  border-radius: 15px;
}

.medicineList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.medicineDiv {
  width: 90px;
  height: 90px;
  margin: 10px;
}

.medicineInfo {
  /* viewpointのwidth - margin - image - 右のmargin */
  width: calc(100vw - 100px - 110px - 10px);
  height: 110px;
  /* 横方向中詰め追加 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.medicineTitle {
  width: 100%;
  display: block;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.medicineFrequency {
  margin-top: 10px;

  display: flex;
  align-items: center;

  letter-spacing: 0.05em;
}