.root {
  width: 65px;
  height: 30px;
  padding-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--psp-gray04);
  border-radius: 15px;
}

.text {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.05em;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 5px;
  top: 5px;
  border: 1px solid var(--psp-black);
  border-radius: 10px;
  background-color: var(--psp-gray04);
}

.checkedImg {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  object-fit: cover;
  object-position: left;
}

.checking.root {
  animation: page-selector-background-highlight 160ms forwards;
}

.checking .text {
  animation: page-selector-text-highlight 300ms forwards;
}

.checking .circle {
  animation: page-selector-circle-highlight 300ms forwards;
}

.checking .checkedImg {
  width: 0px;
  left: 5px;
  animation: page-selector-check-highlight 440ms 160ms forwards;
}

.checked.root {
  background-color: var(--psp-black);
}

.checked .text {
  color: var(--psp-white);
}

.checked .circle {
  background-color: var(--psp-white);
  border: 0;
}

.checked .checkedImg {
  width: 10px;
  left: 5px;
}

@keyframes page-selector-background-highlight {
  100% {
    background-color: var(--psp-black);
  }
}

@keyframes page-selector-text-highlight {
  100% {
    color: var(--psp-white);
  }
}

@keyframes page-selector-circle-highlight {
  100% {
    background-color: var(--psp-white);
    border: 0;
  }
}

@keyframes page-selector-check-highlight {
  100% {
    width: 8px;
  }
}
