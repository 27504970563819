.inputLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: var(--psp-gray01);
}

.medicineNameIconWrapper {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--psp-white);
}

.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.medicineNameIcon {
  margin: 10px auto;
}

.memos {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: var(--psp-black);
  white-space: pre-wrap;
}

.inputWithUnit {
  display: inline-block;
  margin-right: 15px;
}

.unit {
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  letter-spacing: 0.1em;
  color: var(--psp-gray01);
}
