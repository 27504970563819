.pageTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}

.cancelBtn {
  margin: 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}
