.pageTitle {
  height: 34px;

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  letter-spacing: 0.1em;
}

.displayTitle {
  height: 22px;

  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.inputWithUnit {
  width: 162px;
}

.unitDiv {
  height: 28px;

  margin-left: 15px;

  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  letter-spacing: 0.1em;
}

.summaryDiv {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
}
