.weekTitleDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.weekTitleScoreDiv {
  display: flex;
  align-items: center;
  line-height: 28px;
  height: 100%;
}
