.content {
  padding: 45px 25px 25px;
  background-color: var(--psp-white);
  border-radius: 20px !important;
}

.title {
  display: block;
  text-align: center;
}

.scrollableLines {
  max-height: calc(100vh - 450px);
  overflow-y: auto;
}

.card {
  padding: 15px;
  background-color: var(--psp-gray05);
  border-radius: 15px;
}

.selectedCard {
  background-color: var(--psp-pink);
}

.notActiveLabel {
  color: var(--psp-gray02) !important;
}
