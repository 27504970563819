/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --psp-black: #334250;
  --psp-black-rgb: 51, 66, 80;

  --psp-pink: #d3a4a4;
  --psp-pink-rgb: 211, 164, 164;

  --psp-system-blue-light: #007aff;
  --psp-system-blue-light-rgb: 0, 122, 255;

  --psp-system-gray: #f5f5f5;
  --psp-system-gray-rgb: 245, 245, 245;

  --psp-fab-pink: #d38d8d;
  --psp-fab-pink-rgb: 211, 141, 141;

  --red-hue01: #efdad9;
  --red-hue01-rgb: 239, 218, 217;

  --red-hue03: #cb8887;
  --red-hue03-rgb: 203, 136, 135;

  --psp-white: #ffffff;
  --psp-white-rgb: 255, 255, 255;

  --psp-base-gray: #f2f2f2;
  --psp-base-gray-rgb: 242, 242, 242;

  --psp-gray01: #677380;
  --psp-gray01-rgb: 103, 115, 128;

  --psp-gray02: #999c9e;
  --psp-gray02-rgb: 153, 156, 158;

  --psp-gray03: #c4c4c4;
  --psp-gray03-rgb: 196, 196, 196;

  --psp-gray04: #e2e2e2;
  --psp-gray04-rgb: 238, 238, 238;

  --psp-gray05: #f7f7f7;
  --psp-gray04-rgb: 248, 248, 248;

  --psp-blue: #798eae;

  --psp-blue-hue01: #d7dde7;
  --psp-blue-hue01-rgb: 215, 221, 231;

  --psp-blue-hue02: #afbcce;

  --psp-red: #b65554;

  --psp-red-rgb: 182, 85, 84;

  --psp-red-Hue02: #d8a6a5;

  /* 下記のコントロール内部利用する色です */
  --graph-current-btn-color: var(--psp-red-Hue02);
  --graph-avg-btn-color: var(--psp-blue-hue02);

  --graph-current-color: var(--psp-red);
  --graph-avg-color: var(--psp-blue);

  --graph-background-color: var(--psp-gray04);
  --graph-symbol-color: var(--psp-white);

  --text-color: var(--psp-black);
  --text-color-rgb: var(--psp-black-rgb);

  --border-color-rgb: var(--psp-black-rgb);

  --border-color-error-rgb: var(--psp-red-rgb);

  /* --input_border_color: var(--psp-black)00;  */

  --text-secondary-color: var(--psp-white);
  --text-secondary-color-rgb: var(--psp-white-rgb);

  --text-disable-color: var(--psp-gray03);
  --text-disable-color-rgb: var(--psp-gray03-rgb);

  --border: 1px solid var(--psp-black);

  --border-red: 1px solid var(--psp-red);

  --border--disable-color: var(--psp-gray02);

  --text-link-color: var(--psp-black);
  --text-link-color-rgb: var(--psp-black-rgb);

  --text-link-underline-color: var(--psp-gray01);
  --text-link-underline-color-rgb: var(--psp-gray01-rgb);

  --text-sub-title-color: var(--psp-gray01);

  /* tag back color */
  --tag-background: var(--psp-gray01);
  --tag-color: var(--psp-white);

  --tag-background-light: var(--psp-white);
  --tag-color-light: var(--psp-black);

  /* tag button */
  --tag-btn-background: var(--psp-white);
  --tag-btn-color: var(--psp-black);
  --tag-btn-second-color: var(--psp-white);
  --tag-btn-border: 1px solid var(--psp-black);
  --tag-btn-div: var(--psp-gray01);
  --tag-btn-disable-color: var(--psp-gray03);

  /** primary **/
  --ion-color-primary: var(--psp-pink);
  --ion-color-primary-rgb: var(--psp-pink-rgb);
  --ion-color-primary-contrast: var(--text-color);
  --ion-color-primary-contrast-rgb: var(--text-color-rgb);
  --ion-color-primary-shade: var(--red-hue03);
  --ion-color-primary-tint: var(--red-hue01);

  /** secondary **/
  --ion-color-secondary: var(--psp-white);
  --ion-color-secondary-rgb: var(--psp-white-rgb);
  --ion-color-secondary-contrast: var(--text-color);
  --ion-color-secondary-contrast-rgb: var(--text-color-rgb);
  --ion-color-secondary-shade: var(--psp-gray01);
  --ion-color-secondary-tint: var(--psp-white);

  --ion-color-black: #334250;
  --ion-color-black-rgb: 51, 66, 80;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #2d3a46;
  --ion-color-black-tint: #475562;

  --ion-color-gray01: #677380;
  --ion-color-gray01-rgb: 103, 115, 128;
  --ion-color-gray01-contrast: #ffffff;
  --ion-color-gray01-contrast-rgb: 255, 255, 255;
  --ion-color-gray01-shade: #5b6571;
  --ion-color-gray01-tint: #76818d;

  --ion-color-gray02: #999c9e;
  --ion-color-gray02-rgb: 153, 156, 158;
  --ion-color-gray02-contrast: #000000;
  --ion-color-gray02-contrast-rgb: 0, 0, 0;
  --ion-color-gray02-shade: #87898b;
  --ion-color-gray02-tint: #a3a6a8;

  --ion-color-gray03: #c4c4c4;
  --ion-color-gray03-rgb: 196, 196, 196;
  --ion-color-gray03-contrast: #000000;
  --ion-color-gray03-contrast-rgb: 0, 0, 0;
  --ion-color-gray03-shade: #acacac;
  --ion-color-gray03-tint: #cacaca;

  --ion-color-gray04: #e2e2e2;
  --ion-color-gray04-rgb: 226, 226, 226;
  --ion-color-gray04-contrast: #000000;
  --ion-color-gray04-contrast-rgb: 0, 0, 0;
  --ion-color-gray04-shade: #c7c7c7;
  --ion-color-gray04-tint: #e5e5e5;

  --ion-color-gray05: #f8f8f8;
  --ion-color-gray05-rgb: 248, 248, 248;
  --ion-color-gray05-contrast: #000000;
  --ion-color-gray05-contrast-rgb: 0, 0, 0;
  --ion-color-gray05-shade: #dadada;
  --ion-color-gray05-tint: #f9f9f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-orange: #c98b69;
  --ion-color-orange-rgb: 201, 139, 105;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #b17a5c;
  --ion-color-orange-tint: #ce9778;

  --ion-color-yellow: #d3c682;
  --ion-color-yellow-rgb: 211, 198, 130;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #baae72;
  --ion-color-yellow-tint: #d7cc8f;

  --ion-color-green: #79ae95;
  --ion-color-green-rgb: 121, 174, 149;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #6a9983;
  --ion-color-green-tint: #86b6a0;

  --ion-color-blue: #798eae;
  --ion-color-blue-rgb: 121, 142, 174;
  --ion-color-blue-contrast: #000000;
  --ion-color-blue-contrast-rgb: 0, 0, 0;
  --ion-color-blue-shade: #6a7d99;
  --ion-color-blue-tint: #8699b6;

  --ion-color-purple: #a079ae;
  --ion-color-purple-rgb: 160, 121, 174;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0, 0, 0;
  --ion-color-purple-shade: #8d6a99;
  --ion-color-purple-tint: #aa86b6;

  --ion-color-red-hue01: #efdad9;
  --ion-color-red-hue01-rgb: 239, 218, 217;
  --ion-color-red-hue01-contrast: #000000;
  --ion-color-red-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-red-hue01-shade: #d2c0bf;
  --ion-color-red-hue01-tint: #f1dedd;

  --ion-color-orange-hue01: #efdcd2;
  --ion-color-orange-hue01-rgb: 239, 220, 210;
  --ion-color-orange-hue01-contrast: #000000;
  --ion-color-orange-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-orange-hue01-shade: #d2c2b9;
  --ion-color-orange-hue01-tint: #f1e0d7;

  --ion-color-yellow-hue01: #f2eeda;
  --ion-color-yellow-hue01-rgb: 242, 238, 218;
  --ion-color-yellow-hue01-contrast: #000000;
  --ion-color-yellow-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-hue01-shade: #d5d1c0;
  --ion-color-yellow-hue01-tint: #f3f0de;

  --ion-color-green-hue01: #d7e7df;
  --ion-color-green-hue01-rgb: 215, 231, 223;
  --ion-color-green-hue01-contrast: #000000;
  --ion-color-green-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-green-hue01-shade: #bdcbc4;
  --ion-color-green-hue01-tint: #dbe9e2;

  --ion-color-blue-hue01: #d7dde7;
  --ion-color-blue-hue01-rgb: 215, 221, 231;
  --ion-color-blue-hue01-contrast: #000000;
  --ion-color-blue-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-blue-hue01-shade: #bdc2cb;
  --ion-color-blue-hue01-tint: #dbe0e9;

  --ion-color-purple-hue01: #e3d7e7;
  --ion-color-purple-hue01-rgb: 227, 215, 231;
  --ion-color-purple-hue01-contrast: #000000;
  --ion-color-purple-hue01-contrast-rgb: 0, 0, 0;
  --ion-color-purple-hue01-shade: #c8bdcb;
  --ion-color-purple-hue01-tint: #e6dbe9;

  /* background color */
  --ion-background-color: #f1e8e7;
  --ion-background-color-rgb: 241, 232, 231;

  /* font setting */
  --ion-font-family: "Noto Sans JP", "Inter" !important;
  --ion-default-font: "Noto Sans JP", "Inter" !important;

  /* grid default padding */
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;

  --ion-text-color: #334250;
  --ion-text-color-rgb: 51, 66, 80;

  /* AppCheckbox */
  --appcheckbox-normal-width: 44px;
  --appcheckbox-normal-height: 44px;
  --appcheckbox-small-width: 26px;
  --appcheckbox-small-height: 26px;
  --appcheckbox-checkmark-normal-width: 34px;
  --appcheckbox-checkmark-normal-height: 34px;
  --appcheckbox-checkmark-small-width: 20px;
  --appcheckbox-checkmark-small-height: 20px;
  --appcheckbox-close-normal-width: 26px;
  --appcheckbox-close-normal-height: 26px;
  --appcheckbox-close-small-width: 16px;
  --appcheckbox-close-small-height: 16px;

  /* Primary Button & Secondary Button */
  --appbutton-normal-width: 340px;
  --appbutton-small-width: 115px;
  --appbutton-small-width-icon: 142px;

  /* backdrop color and opacity */
  --ion-backdrop-color: #000;
  --ion-backdrop-opacity: 0.6;
  --ion-safe-area-top: 44px;
  --psp-safe-area-top: 14px;
  --psp-safe-area-bottom: 30px;

  --head-icon-width: 44px;
  --margin-width: 25px;

  /* page margin left and right */
  --psp-page-left-right-space: 25px;
  --psp-page-left-right-space-negative: -25px;

  --psp-all-margin: 50px;

  --psp-small-title-width: 160px;
  --psp-small-title-height: 160px;
  --psp-small-title-height-lite: 122px;
}

@media only screen and (max-width: 389px) {
  :root {
    --psp-page-left-right-space: 20px;
    --psp-page-left-right-space-negative: -20px;
  }
}

@media only screen and (max-width: 374px) {
  :root {
    --psp-page-left-right-space: 16px;
    --psp-page-left-right-space-negative: -16px;
  }
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black) !important;
  --ion-color-base-rgb: var(--ion-color-black-rgb) !important;
  --ion-color-contrast: var(--ion-color-black-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-black-shade) !important;
  --ion-color-tint: var(--ion-color-black-tint) !important;
}

.ion-color-red-hue01 {
  --ion-color-base: var(--ion-color-red-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-red-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-red-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-red-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-red-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-red-hue01-tint) !important;
}

.ion-color-orange-hue01 {
  --ion-color-base: var(--ion-color-orange-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-orange-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-orange-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-orange-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-orange-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-orange-hue01-tint) !important;
}

.ion-color-yellow-hue01 {
  --ion-color-base: var(--ion-color-yellow-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-yellow-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-yellow-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-yellow-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-yellow-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-yellow-hue01-tint) !important;
}

.ion-color-green-hue01 {
  --ion-color-base: var(--ion-color-green-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-green-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-green-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-green-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-green-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-green-hue01-tint) !important;
}

.ion-color-blue-hue01 {
  --ion-color-base: var(--ion-color-blue-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-blue-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-blue-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-blue-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-blue-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-blue-hue01-tint) !important;
}

.ion-color-purple-hue01 {
  --ion-color-base: var(--ion-color-purple-hue01) !important;
  --ion-color-base-rgb: var(--ion-color-purple-hue01-rgb) !important;
  --ion-color-contrast: var(--ion-color-purple-hue01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-purple-hue01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-purple-hue01-shade) !important;
  --ion-color-tint: var(--ion-color-purple-hue01-tint) !important;
}

.ion-color-gray01 {
  --ion-color-base: var(--ion-color-gray01) !important;
  --ion-color-base-rgb: var(--ion-color-gray01-rgb) !important;
  --ion-color-contrast: var(--ion-color-gray01-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gray01-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gray01-shade) !important;
  --ion-color-tint: var(--ion-color-gray01-tint) !important;
}

.ion-color-gray02 {
  --ion-color-base: var(--ion-color-gray02) !important;
  --ion-color-base-rgb: var(--ion-color-gray02-rgb) !important;
  --ion-color-contrast: var(--ion-color-gray02-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gray02-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gray02-shade) !important;
  --ion-color-tint: var(--ion-color-gray02-tint) !important;
}

.ion-color-gray03 {
  --ion-color-base: var(--ion-color-gray03) !important;
  --ion-color-base-rgb: var(--ion-color-gray03-rgb) !important;
  --ion-color-contrast: var(--ion-color-gray03-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gray03-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gray03-shade) !important;
  --ion-color-tint: var(--ion-color-gray03-tint) !important;
}

.ion-color-gray04 {
  --ion-color-base: var(--ion-color-gray04) !important;
  --ion-color-base-rgb: var(--ion-color-gray04-rgb) !important;
  --ion-color-contrast: var(--ion-color-gray04-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gray04-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gray04-shade) !important;
  --ion-color-tint: var(--ion-color-gray04-tint) !important;
}

.ion-color-gray05 {
  --ion-color-base: var(--ion-color-gray05) !important;
  --ion-color-base-rgb: var(--ion-color-gray05-rgb) !important;
  --ion-color-contrast: var(--ion-color-gray05-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gray05-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gray05-shade) !important;
  --ion-color-tint: var(--ion-color-gray05-tint) !important;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white) !important;
  --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-white-shade) !important;
  --ion-color-tint: var(--ion-color-white-tint) !important;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange) !important;
  --ion-color-base-rgb: var(--ion-color-orange-rgb) !important;
  --ion-color-contrast: var(--ion-color-orange-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-orange-shade) !important;
  --ion-color-tint: var(--ion-color-orange-tint) !important;
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow) !important;
  --ion-color-base-rgb: var(--ion-color-yellow-rgb) !important;
  --ion-color-contrast: var(--ion-color-yellow-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-yellow-shade) !important;
  --ion-color-tint: var(--ion-color-yellow-tint) !important;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green) !important;
  --ion-color-base-rgb: var(--ion-color-green-rgb) !important;
  --ion-color-contrast: var(--ion-color-green-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-green-shade) !important;
  --ion-color-tint: var(--ion-color-green-tint) !important;
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue) !important;
  --ion-color-base-rgb: var(--ion-color-blue-rgb) !important;
  --ion-color-contrast: var(--ion-color-blue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-blue-shade) !important;
  --ion-color-tint: var(--ion-color-blue-tint) !important;
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple) !important;
  --ion-color-base-rgb: var(--ion-color-purple-rgb) !important;
  --ion-color-contrast: var(--ion-color-purple-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-purple-shade) !important;
  --ion-color-tint: var(--ion-color-purple-tint) !important;
}

.ion-page {
  --ion-background-color: #f1e8e7;
  background-color: #f1e8e7;

  --ion-text-color: #334250;
  --ion-text-color-rgb: 51, 66, 80;
}

body {
  font-style: normal !important;
}

@font-face {
  font-family: "Noto Sans JP";
  src: local("Noto Sans JP");
  unicode-range: U+A5, U+4E00-9FFF, U+30??, U+FF00-FF9F;
}

.secondButton {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.1em;

  color: #334250;

  --border-width: 1px !important;
  --border-color: #334250 !important;
  --border-style: solid;
  --color: #334250 !important;
}

/* button */
.button.button-outline {
  --border-width: 1px;
  --border-color: #334250;
  --color: #334250;
}

.button {
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
  --box-shadow: none;
  --border-radius: 1.75rem;
  letter-spacing: 0.1rem;
}

.page-spacer-120 {
  height: 7.5rem;
}

.page-spacer-100 {
  height: 6.25rem;
}

.page-spacer-90 {
  height: 5.625rem;
}

.page-spacer-80 {
  height: 5rem;
}

.page-spacer-75 {
  height: 4.6875rem;
}

.page-spacer-70 {
  height: 4.375rem;
}

.page-spacer-65 {
  height: 4.0625rem;
}

.page-spacer-60 {
  height: 3.75rem;
}

.page-spacer-55 {
  height: 3.4375rem;
}

.page-spacer-50 {
  height: 3.125rem;
}

.page-spacer-45 {
  height: 2.8125rem;
}

.page-spacer-40 {
  height: 2.5rem;
}

.page-spacer-35 {
  height: 2.188rem;
}

.page-spacer-30 {
  height: 1.875rem;
}

.page-spacer-25 {
  height: 1.5625rem;
}

.page-spacer-20 {
  height: 1.25rem;
}

.page-spacer-15 {
  height: 0.9375rem;
}

.page-spacer-13 {
  height: 0.8125rem;
}

.page-spacer-11 {
  height: 0.6875rem;
}

.page-spacer-10 {
  height: 0.625rem;
}

.page-spacer-8 {
  height: 0.5rem;
}

.page-spacer-7 {
  height: 0.4375rem;
}

.page-spacer-5 {
  height: 0.3125rem;
}

.page-width-spacer-15 {
  width: 0.9375rem;
}

.page-width-spacer-10 {
  width: 0.625rem;
}

.page-width-spacer-5 {
  width: 0.3125rem;
}

.score-chart-panel {
  /* margin-left: 2%; */
}

.home-recommended-activities {
  height: 10.625rem;
}

.slide {
  width: 296px;
  justify-content: start !important;
}

.margin-0 {
  margin: 0;
}

/* change the default handle position in modal */
.modal-sheet::part(handle) {
  top: 20px;
  width: 70px;
}

/* change the default padding position in list -> item */
.list-md-lines-none .item::part(native) {
  padding-left: 0;
}

.slides-md {
  --bullet-background-active: var(--psp-red);
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../assets/fonts/NotoSansJP-Regular.otf");
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-VariableFont_slnt,wght.ttf");
}
