.content_left {
  margin-left: var(--psp-page-left-right-space) !important;
}

.notice {
  font-family: "Noto Sans JP";
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */
  letter-spacing: 0.1em;
}

.view_record_btn_div {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.1em;
}

/* .slide {
  justify-content: start !important;
} */

.slides_bar {
  /* margin-top: 20px; */
  height: 160px;
}

.slides_bar_short {
  height: 122px;
}

.slide_left {
  width: 160px !important;
  justify-content: start !important;
}

.media_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  letter-spacing: 0.05em;
}

.fab_chat_size {
  width: 44px;
  height: 44px;
}

.home_recommended_activities {
  height: 10.625rem;
}

.slide {
  width: 282px;
  justify-content: start !important;
}

.faceWrapper > div:not(:last-child) {
  margin-right: 24px;
}

.faceIcon,
.currectFaceIcon {
  height: 36px;
  position: relative;
}

.faceIcon::before,
.currectFaceIcon::before {
  position: absolute;
  content: "";
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 20px;
  background-color: var(--psp-gray05);
}

.currectFaceIcon::before {
  background-color: var(--psp-pink);
}

.labelWithRightIcon {
  margin-right: 5px;
}
