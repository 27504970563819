.medicine_item_iconCard {
  height: 110px;
  width: 100%;
  background-color: var(--psp-white);
  border-radius: 15px;
  --ion-card-color: (--text-color) !important;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.medicine_item_ionCardContent {
  /* margin: 0 18px 0 25px; */
  padding: 0;
}

.medicineList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.medicineDiv {
  width: 90px;
  height: 90px;
  margin: 10px;
}

.medicineInfo {
  height: 110px;
  width: 215px;
}

.medicineTitle {
  width: 100%;

  margin-top: 15px;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: block;
  align-items: center;
  letter-spacing: 0.1em;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.medicineFrequency {
  margin-top: 10px;

  font-weight: 400;
  font-size: 12px;
  line-height: 19px;

  display: flex;
  align-items: center;
}

.medicineNum {
  margin-top: 5px;

  font-weight: 400;
  font-size: 12px;
  line-height: 19px;

  display: flex;
  align-items: center;
}

.list_item {
  --inner-padding-end: 0;
  margin-bottom: 15px;
}

.list_item_year {
  --padding-start: 0;
  --inner-padding-end: 0;

  height: 22px;
  margin-top: 45px;
  margin-bottom: 30px;

  display: flex;
  justify-content: flex-start;
}
