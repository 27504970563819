.label {
  width: calc(100vw - var(--psp-page-left-right-space) - var(--psp-page-left-right-space) - 128px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toggle {
  margin-left: 15px;
}

.handle {
  padding: 22px 0 22px 15px;
}
