.root {
  display: inline-block;
  position: relative;
  width: 100%;
}

.shadowDiv {
  margin-top: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--psp-base-gray);
  border-radius: 15px;
}

.title {
  width: max-content;
  margin: 20px auto 20px;
  padding: 1px 10px;
  border-radius: 11px;
  background-color: var(--psp-gray01);
  color: var(--psp-white);
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
}

.icon {
  fill: var(--psp-red);
}

.desc {
  width: max-content;
  margin: 20px auto 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  color: var(--psp-black);
}
