.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.number {
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 12px;
  color: var(--psp-black);
  line-height: 19px;
  letter-spacing: 0.05em;
}

.numberAnimation {
  animation: quantity-label-update 300ms;
}

@keyframes quantity-label-update {
  0%,
  100% {
    opacity: 1;
  }
  33%,
  66% {
    opacity: 0;
  }
}
