.titleBar {
  width: 212px !important;
  margin: 0 auto;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendarTableHead {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--psp-black);

  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarDateDiv {
  height: 34px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: center;
}

.disableCalendarDateColor {
  color: var(--psp-gray03) !important;
}

.calendarDateCurrentDiv {
  height: 34px;
  width: 34px;
  background-color: var(--psp-gray01);
  border-radius: 50%;
  color: var(--psp-white);

  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarDateIsSelectBorderDiv {
  height: 40px;
  width: 40px;
  border: 1px solid var(--psp-black);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.calendarDateIsSelectDiv {
  min-height: 34px;
  min-width: 34px;
  background-color: var(--psp-pink);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.legendRoot {
  max-width: 280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend > ion-label {
  margin-left: 5px;
}

/* The reason not using gap in flex is because gap is not well supported in flex layout by some older browser
  https://caniuse.com/?search=gap
*/
.legend:not(:last-child) > ion-label {
  margin-right: 20px;
}

.shortLabel,
.label {
  position: relative;
  padding-left: 1px;
}

.shortLabel {
  padding-left: 2px;
}

.shortLabel.white,
.label.white {
  color: var(--psp-white) !important;
}

.finishedPoint {
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translateX(-50%);
  display: flex;
}

.unClickable {
  color: var(--psp-gray02) !important;
}

.firstSelectedDot::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  background-color: var(--psp-pink);
  z-index: -1;
}

.firstSelectedDot::after {
  position: absolute;
  content: "";
  left: -3px;
  top: -3px;
  right: 0;
  bottom: -3px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid var(--psp-black);
  border-right: none;
}

.middleSelectedDot::before {
  position: absolute;
  content: "";
  left: -1px;
  top: 0;
  right: -1px;
  bottom: 0;
  background-color: var(--psp-pink);
  z-index: -1;
}

.middleSelectedDot::after {
  position: absolute;
  content: "";
  left: -0px;
  top: -3px;
  right: -0px;
  bottom: -3px;
  border-top: 1px solid var(--psp-black);
  border-bottom: 1px solid var(--psp-black);
}

.lastSelectedDot::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: var(--psp-pink);
  z-index: -1;
}

.lastSelectedDot::after {
  position: absolute;
  content: "";
  left: 0;
  top: -3px;
  right: -3px;
  bottom: -3px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid var(--psp-black);
  border-left: none;
}
