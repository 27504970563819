.root {
  position: relative;
}

.floatLeftArrow {
  position: absolute;
  top: 6px;
  left: 48px;
}

.floatRightArrow {
  position: absolute;
  top: 6px;
  right: -12px;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
}

.tootip {
  padding: 15px;
  background-color: var(--psp-white);
  border-radius: 15px;
}

.tootipTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: var(--text-color);
}

.tooltipContent {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--text-color);
}

.tooltipScore {
  display: inline-block;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #be6a69;
}

.graphMin {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  fill:"red";
}

.containerDiv {
  width: 100%;
  position: relative;
}

.emptyMsgDiv {
  width: 100%;
  height: 240px;
  position: absolute;
  top: 60;
  left: 0;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.emptyMsg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  width: 200px;
  height: 70px;

  /* Primary/PSP White */

  background: var(--psp-white);
  border-radius: 15px;
  color: var(--psp-black);

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}