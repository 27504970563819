.chartDiv {
  width: 108px;
  height: 147px;
  background: var(--psp-white);
  border-radius: 15px;
}

.monthDiv {
  margin: 0 15px;
  width: auto;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.05em;
}

.scoreText {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;

  /* 横展開色を修正します。 */
  color: var(--psp-red);
}

.lineTypeTitle {
  margin: 0 15px;
  width: auto;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
}

.lineChartDiv {
  background: var(--psp-white);
  border-radius: 15px;
  width: auto;
}

.lineChartMonthDiv {
  margin: 0 15px;
  width: auto;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */
}

.lineChartMedicineDiv {
  margin: 0 15px;
  width: auto;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
}

.weekDetailDiv {
  background: var(--psp-white);
  border-radius: 15px;
  padding: 0 25px;
  color: var(--text-color);
}

.weekTitleDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.weekTitleScoreDiv {
  display: flex;
  line-height: 28px;
  height: 100%;
}

.weekTitleDateDiv {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: 0.05em;
}

.weekTitleScoreTextDiv {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: 0.1em;
}

.rankDiv {
  height: 100%;
  display: flex;
  align-items: center;
}

.medicineTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;

  /* Primary/PSP gray02 */
  color: var(--psp-gray02);
}

.medicineDetail {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  /* Primary/PSP Black */
  color: var(--text-color);
}

.displayTitle {
  color: var(--text-color);

  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
}

.displayTitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.containerDiv {
  width: 100%;
  position: relative;
}

.emptyMsgDiv {
  width: 100%;
  height: 240px;
  position: absolute;
  top: 80px;
  left: 0;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.emptyMsg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  width: 200px;
  height: 70px;

  /* Primary/PSP White */

  background: var(--psp-white);
  border-radius: 15px;
  color: var(--psp-black);

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}
