.prescription_item_iconCard {
  width: 100%;
  background-color: var(--psp-white);
  border-radius: 15px;
  --ion-card-color: (--text-color) !important;
  margin: 0;
  box-shadow: none;
  --ion-grid-column-padding: 0;
}

.prescription_item_ionCardContent {
  padding: 15px 15px 18px 15px;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blankText {
  color: var(--psp-gray03) !important;
}

.right_div {
  display: flex;
  justify-content: flex-end;
}

.item_tag {
  padding: 1px 10px;
  border-radius: 5px;
  color: var(--psp-white) !important;
}
