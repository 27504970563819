.root {
}

.root > div {
  background-color: var(--psp-white);
}

.headerWrapper {
  position: relative;
}

.backBtn {
  position: absolute;
  top: -6px;
}

.deviceImgWrapper {
  width: 100%;
}

.deviceImgWrapper img {
  display: block;
  margin: 0 auto;
}

.deviceImg {
  height: 153px;
}

.deviceConnectionImg {
  height: 200px;
}

.deviceConnectingImg {
  height: 100px;
}
