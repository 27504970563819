.card {
  min-height: 84px;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  background-color: var(--psp-white);
  border-radius: 15px;
}

.cardActionSheetRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
