.model {
  --border-radius: 0 !important;
}

.divContainer {
  width: 100%;
  height: 100%;
  background: var(--psp-black);
}

.video {
  width: 100%;
  height: 100%;
}
