.root {
  width: 200vw;
  display: flex;
  transition: transform 300ms;
}

.sliderWrapper {
  width: 100vw;
  transform: translateX(var(--psp-page-left-right-space-negative));
}

.moveLeft {
  transform: translateX(calc(-100vw));
}

.body {
  padding-left: var(--psp-page-left-right-space);
  padding-right: var(--psp-page-left-right-space);
}

.scrollable {
  height: calc(100vh - var(--ion-safe-area-top) - 79px - 36px - 45px) !important;
  /* align-items: flex-start; */
  overflow-y: auto;
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: var(--psp-page-left-right-space);
  padding-right: var(--psp-page-left-right-space);
}

.cancel,
.confrim {
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--psp-black);
}

.confrim {
  text-align: right;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--psp-black);
}

.optionLine {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
}

.item,
.label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--psp-black);
}

.inputWithUnit {
  margin-right: 15px;
}
