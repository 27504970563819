.title {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
}

.imgCol {
  --ion-grid-column-padding: 10px;
}
