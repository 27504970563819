.deviceImgWrapper {
  width: 100%;
}

.deviceImgWrapper img {
  display: block;
  margin: 0 auto;
}

.deviceImg {
  height: 153px;
}

.deviceConnectingImg {
  height: 100px;
}

.submitWrapper {
  padding-top: 15px;
}
