.dspHeadDiv {
  display: flex;
}

.rank {
  width: 30px;
  height: 30px;
  padding-right: 2px;
  padding-bottom: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: var(--psp-white) !important;
}

.categoryName {
  display: flex;
  align-items: center;
  font-style: normal;
}

.question {
  color: var(--psp-gray01) !important;
}

.answer {
  max-width: 96px;
  display: block;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multiAnswer:not(:last-child) {
  margin-bottom: 10px;
}
