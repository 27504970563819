.tabWrapper {
  --ion-grid-padding: 0;
}

/* 指摘に応じて、ボーダーのサイズを2に変更しました。 */
.tabItem {
  min-width: max-content;
  padding: 0 5px 10px 5px;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var(--psp-gray03);
  border-radius: 1px;
}

.active::after {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  content: "";
  background: var(--psp-pink);
  border-radius: 1px;
}
