.input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  letter-spacing: 0.1em;

  color: var(--psp-gray01);
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.input_div {
  position: relative;
  /* display: inline-block; */
  /* width: 340px; */
  width: 100%;
  height: 44px;
}

.input {
  position: relative;
  width: 100%;
  --padding-start: 20px !important;

  height: 44px;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 160% */

  letter-spacing: 0.05em;

  color: var(--text-color);

  background: var(--psp-white);
  border-radius: 22px;

  /* show text etc symbol */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* focus style */
.input_no_focus {
  --padding-end: 20px !important;
}

.input_focus {
  --padding-end: 49px !important;
  animation: change_border 0.25s forwards;
}

/* focus animation */
@keyframes change_border {
  0% {
    border: 1px solid rgba(var(--border-color-rgb), 0);
  }
  100% {
    border: 1px solid rgba(var(--border-color-rgb), 1);
  }
}

.input_error {
  --padding-end: 49px !important;
  color: var(--psp-red) !important;
  border: 1px solid var(--psp-red) !important;
}

.icon_error {
  /* fill: var(--psp-red); */
  fill: var(--psp-red) !important;
  opacity: 1 !important;
}

.input_icon,
.icon_close {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 2;
}

.icon_close {
  fill: rgba(var(--text-color-rgb), 0);
  /* opacity: 0; */
}

.icon_close_focus {
  animation: change_close_btn 0.25s forwards;
}

@keyframes change_close_btn {
  0% {
    fill: rgba(var(--text-color-rgb), 0);
  }
  100% {
    fill: rgba(var(--text-color-rgb), 1);
  }
}

.shake {
  animation: shake_div 0.3s cubic-bezier(0.01, 0.42, 0.35, 1);
}

@keyframes shake_div {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(2px);
  }
  46.4% {
    transform: translateX(-4px);
  }
  59.8% {
    transform: translateX(4px);
  }
  73.2% {
    transform: translateX(-2px);
  }
  86.6% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}

.rotateIcon {
  transform: rotate(180deg);
}

.textArea {
  margin: 0;
  --padding-bottom: 15px;
  --padding-end: 15px;
  --padding-start: 15px;
  --padding-top: 15px;
  --color: var(--psp-black);
  --background: var(--psp-white);
  border-radius: 15px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05rem;
}

.textArea textarea:focus::placeholder {
  color: transparent !important;
}

.input_div.full,
.textArea.full {
  width: var(--appbutton-normal-width);
  min-width: var(--appbutton-normal-width);
}

.input_div.md,
.textArea.md {
  width: 162px;
  min-width: 162px;
}

.input_div.sm,
.textArea.sm {
  width: 100px;
  min-width: 100px;
}

.input_div.xs,
.textArea.xs {
  width: 80px;
  min-width: 80px;
}
