.content {
  background-color: var(--psp-white);
  border-radius: 20px !important;
  color: var(--text-color);

  margin-left: calc(-1 * var(--psp-page-left-right-space));
  margin-right: calc(-1 * var(--psp-page-left-right-space));
}

.titleButton {
  width: 80px;
}

.titleButtonEnd {
  text-align: end;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height, or 139% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
}

.checkTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}

.checkDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrollable {
  height: calc(100vh - var(--ion-safe-area-top) - 79px - 36px - 45px) !important;
  overflow-y: auto;
}

.scrollContent {
  margin-left: var(--psp-page-left-right-space);
  margin-right: var(--psp-page-left-right-space);
}

.titleWithIcon {
  margin-left: 18px;
}
