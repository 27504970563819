.chatToolBar {
  display: grid;
}
.chatHead {
  background-color: beige;
}

.cardContent {
  background-color: #f3f3f3;
}

.CardTitle {
  background-color: #f3f3f3;
}

.timeCol {
  display: flex;
  align-items: flex-end;
}
.chatModal {
  background-color: #313131;
}
.questionText {
  margin-top: 1em;
  font-size: x-large;
  margin-bottom: 1em;
}
ion-modal {
  --border-radius: 20px !important;
}
