.text-center {
  text-align: center;
}

.text-block {
  display: block;
}

.text-center-block {
  display: block;
  text-align: center;
}

.text-right {
  text-align: right;
}

.pre-wrap {
  white-space: pre-wrap;
}

.p-header1 {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}

.p-header2 {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: var(--psp-black);
}

.p-header3 {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: var(--psp-black);
}

.p-header4 {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}

.p-body {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}

.p-body.gray {
  color: var(--psp-gray01);
}

.p-smallbody {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--psp-black);
}
.p-smallbody.white {
  color: var(--psp-white);
}

.p-smallbody-regular {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--psp-black);
}

.p-capition {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--psp-black);
}

.p-graph-min {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--psp-black);
}

.p-total-score-diagram {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--psp-black);
}

.p-score-panel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.chart_group {
  color: var(--psp-black);
}

.item_group {
  color: var(--psp-red);
}

.p-history-year {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--psp-black);
}

.p-calendar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--psp-black);
}

.p-score-diagram {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1em;
  color: var(--psp-black);
}

.p-input-label-error {
  position: absolute;
  left: 0;
  bottom: -25px;

  margin-left: 20px;
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: var(--psp-red);
}

.p-page-label-error-block {
  display: block;
  text-align: center;

  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #c34f4f;
}

.p-caption {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: var(--psp-black);
}

.listTitle ion-label[class*="p-body"] {
  color: var(--psp-gray01) !important;
}

.listTitle ion-label[class*="p-smallbody"] {
  letter-spacing: 0.05em;
}
