.list_item {
  --inner-padding-end: 0;
  margin-bottom: 15px;
}

.list_item_year {
  --padding-start: 0;
  --inner-padding-end: 0;

  height: 22px;
  margin-top: 45px;
  margin-bottom: 30px;

  display: flex;
  justify-content: flex-start;
}
