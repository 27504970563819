.pin-field {
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 2.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 2rem;
}

.pin-field:focus {
  border-color: rgb(0, 123, 255);
  outline: none;
  transform: scale(1.05);
}

.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

swd-pin-field[completed] .pin-field {
  border-color: rgb(40, 167, 69);
  background-color: rgba(40, 167, 69, 0.25);
}
/* login page css */
.login_head_div {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

/* .login_input_container .login_input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  
  letter-spacing: 0.1em;
  
  color: #999C9E;
}

.login_input_container .login_input {
  --padding-start: 20px !important;
  --padding-end: 20px !important;
  
  height:44px;

  font-weight: 500;
  font-size: 12px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #334250;

  background: #FFFFFF;
  border-radius: 22px;
}

.login_input_container .login_input_border {
  border: 1px solid #d38d8d;
  color: #B17979;
} */

.login_link_div {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  letter-spacing: 0.1em;

  color: #334250;
}
