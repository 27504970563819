.root {
  position: relative;
}

.floatLeftArrow {
  position: absolute;
  bottom: 9px;
  left: 56px;
  z-index: 1;
}

.floatRightArrow {
  position: absolute;
  bottom: 9px;
  right: -5px;
  z-index: 1;
}

.floatUpArrow {
  position: absolute;
  top: -25px;
  left: 13px;
  z-index: 1;
}

.floatDownArrow {
  position: absolute;
  bottom: -20px;
  left: 13px;
  z-index: 1;
}

.tootip {
  padding: 15px;
  background-color: var(--psp-white);
  border-radius: 15px;
}

.tootipTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: var(--psp-black);
}

.tooltipContent {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--psp-black);
}

.tooltipScore {
  display: inline-block;
  margin-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #be6a69;
}

.chartRoot {
  position: relative;
}

.lineBackground {
  position: absolute;
  top: 3px;
  left: 65px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--psp-gray03);
  z-index: -1;
}
