.TagButton {
  position: relative;
  overflow: hidden;

  height: 22px;
  border-radius: 11px;
  width: 60px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */
  
  text-align: center;
  letter-spacing: 0.05em;

  border: var(--tag-btn-border);

  --background: var(--tag-btn-background);
  --color: var(--tag-btn-color);
}

.TagButton div {
  position: relative;
}

.TagButtonDisabled {
  opacity: 1 !important;
  border-color: var(--tag-btn-disable-color) !important;
  color: var(--tag-btn-disable-color) !important;
}

/* change shape */
.TagButtonAnimation {
  animation: scale_size 0.2s cubic-bezier(0.014, 0.421, 0.347, 1),
    change_color 0.4s cubic-bezier(0.014, 0.421, 0.347, 1) 0.1s;
}

@keyframes scale_size {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.96);
  }
}

@keyframes change_color {
  37.5%,
  100% {
    color: var(--tag-btn-second-color);
  }
}

/* change color */
.TagButtonAnimation > div::before {
  opacity: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 0;
  height: 0;
  background-color: var(--tag-btn-div);
  border-radius: 999px;
  animation: color_change 0.4s cubic-bezier(0.581, 0, 0.347, 1) 0.1s;
}

@keyframes color_change {
  100% {
    width: 60px;
    padding-top: 200%;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;
}
