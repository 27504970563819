.divBackGround {
  width: 100%;
  background: var(--ion-background-color);
}

.confirmBtn {
  position: sticky;
  bottom: 0px;
  z-index: 2;
}

.staticBtn {
  position: static;
}

.btnWrapper {
  padding-left: var(--psp-page-left-right-space);
  padding-right: var(--psp-page-left-right-space);
}
