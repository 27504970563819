.rank {
  width: 30px;
  height: 30px;
  padding-right: 2px;
  padding-bottom: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: var(--psp-white) !important;
}
