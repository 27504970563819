.divContainer {
  width: 340px;
  height: auto;
}

.img {
  width: 340px;
  height: 191px;
  border-radius: 15px;
}

.title {
  font-family: var(--ion-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* or 160% */

  letter-spacing: 0.05em;

  color: var(--psp-gray01);
}
