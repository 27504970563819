.timelineWrapper {
  display: flex;
}

.timelineLeft {
  width: 85px;
  padding-top: 7px;
  padding-right: 40px;
  position: relative;
  text-align: right;
}

.timelineLeft::before {
  width: 3px;
  position: absolute;
  content: "";
  right: -1px;
  background-color: var(--psp-pink);
}

.timelineLeft.none::before {
  background-color: transparent !important;
}

.symptomLine::before {
  background-color: var(--psp-pink);
}

.activityLine::before {
  background-color: var(--ion-color-purple);
}

.blood_pressureLine::before {
  background-color: var(--psp-blue);
}

.body_temperatureLine::before {
  background-color: var(--ion-color-green);
}

.taking_medicineLine::before {
  background-color: var(--ion-color-orange);
}

.timelineLeft.first::before {
  top: 18px;
  bottom: 0;
}

.timelineLeft.middle::before {
  top: 0;
  bottom: 0;
}

.timelineLeft.last::before {
  top: 0;
  height: 18px;
}

.timelineRight {
  flex-grow: 1;
  padding-top: 7px;
  padding-left: 40px;
}

.icon {
  position: absolute;
  top: 6px;
  right: -18px;
}

.symptomItem {
  margin-bottom: 5px;
}

.descLabelWithMargin {
  margin-left: 5px;
}

.descriptionOverflowLabel {
  width: calc(
    100vw - var(--psp-page-left-right-space) - var(--psp-page-left-right-space) - 50px - 85px - 40px
  );
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
