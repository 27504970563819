.iosheader {
  padding-top: var(--psp-safe-area-top);
}

.topBtnRow {
  padding: 30px var(--psp-page-left-right-space) 20px;
}

.topBottomLine {
  border-bottom: 1px solid var(--psp-gray04);
  padding-bottom: 19px;
}

.topBtnRowNoPad {
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 15px 0px;
}

.topBottomLineNoPad {
  border-bottom: 1px solid var(--psp-gray04);
  padding-bottom: 14px !important;
}

.backBtn {
  height: 36px;
  width: 36px;
}

.rightDiv {
  width: calc(var(--head-icon-width) + var(--margin-width));
}

.topBackground {
  background-color: var(--ion-background-color);
}
