.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  background-color: var(--psp-gray02);
  /* transition: 300ms; */
}

.wrapper::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: var(--psp-white);
  /* transition: 300ms; */
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.input:checked + .wrapper {
  background-color: var(--psp-pink);
}

.input:checked + .wrapper::before {
  transform: translateX(20px);
}

.input[disabled] + .wrapper {
  background-color: var(--psp-gray04);
}

.input[disabled]:checked + .wrapper {
  background-color: var(--red-hue01);
}
