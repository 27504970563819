.input {
  opacity: 0;
  width: 0;
  height: 0;
  /* position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%); */
}

.root {
  display: flex;
  align-items: center;
}

.root.vertical {
  flex-direction: column;
}

.init {
  min-width: 44px;
  min-height: 44px;
  margin-right: 15px;
  margin-bottom: 0;
  position: relative;
  background-color: var(--psp-white);
  border: 1px solid var(--psp-black);
  border-radius: 50%;
  z-index: 0;
}

.vertical .init {
  margin-right: 0;
  margin-bottom: 10px;
}

.init::before {
  position: absolute;
  content: "";
  left: 7px;
  top: 7px;
  right: 7px;
  bottom: 7px;
  background-color: var(--psp-pink);
  border-radius: 50%;
  transform: scale(0);
  z-index: -1;
}

.checked.init::before {
  transform: scale(1);
}

.checking::before {
  animation: scale-up-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    checking-opacity 130ms forwards;
}

.unchecking::before {
  animation: scale-down 400ms cubic-bezier(0.014, 0.421, 0.347, 1) forwards,
    unchecking-opacity 200ms forwards;
}

.label {
  font-family: var(--ion-font-family);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.05rem;
  color: var(--psp-black);
}

.disabled.init {
  border-color: var(--psp-gray03);
}

.disabled.checked.init::before {
  background-color: var(--red-hue01);
}

@keyframes scale-up-down {
  0% {
    transform: scale(0);
  }
  66% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checking-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes unchecking-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
