.divContainer {
  width: 390px;
  height: 220px;
  position: relative;
}

.img {
  width: 390px;
  height: 220px;
  display: block;
}

.overlay {
  position: absolute; 
  margin-top: -220px;
  width: 390px;
  height: 220px;
  background-color: rgba(0,0,0, 0.3);
  display: flex;
  flex-direction: row;
}

.imgPlay {
  width: 86px;
  height: 86px;
}

.time {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 48px;
}