.content {
  margin: 0 25px;
}

.displayTitle {
  height: 34px;

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
}
