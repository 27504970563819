.PrimaryButton {
  position: relative;
  overflow: hidden;

  height: 56px;
  border-radius: 28px;
  width: var(--appbutton-normal-width);

  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.fullWidth {
  min-width: var(--appbutton-normal-width);
}

.PrimaryButton div {
  position: relative;
}

.PrimaryButtonDisabled {
  opacity: 1 !important;
  color: var(--border--disable-color) !important;
  --background: var(--ion-color-primary-tint) !important;
}

/* change shape */
.PrimaryButtonAnimation {
  animation: scale_size 0.2s cubic-bezier(0.014, 0.421, 0.347, 1);
}

@keyframes scale_size {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.96);
  }
}

/* change color */
.PrimaryButtonAnimation > div::before {
  opacity: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 0;
  height: 0;
  background-color: var(--ion-color-primary-shade);
  border-radius: 999px;
  animation: color_change 0.4s cubic-bezier(0.581, 0, 0.347, 1) 0.1s;
}

@keyframes color_change {
  100% {
    width: var(--appbutton-normal-width);
    padding-top: var(--appbutton-normal-width);
  }
}

.PrimaryButtonSmall {
  position: relative;
  overflow: hidden;

  height: 44px;
  border-radius: 22px;
  width: var(--appbutton-small-width);

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.05em;

  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.PrimaryButtonSmall.iconBtn {
  width: var(--appbutton-small-width-icon);
}

.PrimaryButtonSmall div {
  position: relative;
}

/* change shape */
.PrimaryButtonSmallAnimation {
  animation: scale_size_small 0.2s cubic-bezier(0.014, 0.421, 0.347, 1);
}

@keyframes scale_size_small {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.96);
  }
}

/* change color */
.PrimaryButtonSmallAnimation > div::before {
  opacity: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 0;
  height: 0;
  background-color: var(--ion-color-primary-shade);
  border-radius: 999px;
  animation: color_change_small 0.4s cubic-bezier(0.581, 0, 0.347, 1) 0.1s;
}

.PrimaryButtonSmallAnimation.iconBtn div::before {
  animation: color_change_small_icon 0.4s cubic-bezier(0.581, 0, 0.347, 1) 0.1s;
}

@keyframes color_change_small {
  100% {
    width: var(--appbutton-small-width);
    padding-top: var(--appbutton-small-width);
  }
}

@keyframes color_change_small_icon {
  100% {
    width: var(--appbutton-small-width-icon);
    padding-top: var(--appbutton-small-width-icon);
  }
}

.label {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
}

.PrimaryButtonSmall .label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
}

.icon {
  margin-left: 5px;
  height: 16px !important;
  width: 16px !important;
}

.PrimaryButtonSmall .icon {
  height: 12px !important;
  width: 12px !important;
}
