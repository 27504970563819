.chartBarArea {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 0;
}

.highlight {
  z-index: -2;
}

.highlightBar {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 34px;
  border-radius: 17px;
  background-color: var(--psp-gray03);
  opacity: 0.3;
}
