.cancelLabel {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-align: center;
}

.submitWrapper {
  padding-top: 15px;
}
